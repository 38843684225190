import React from 'react'
import {
  Modal,
  StyleSheet,
  View,
  Text,
  Pressable,
  FlatList,
} from 'react-native'
import { concat } from 'lodash'
import { ITag } from '../api/tags'
import Colors from '../constants/Colors'
import { contentTags } from '../constants/Config'
import { UserConsumer } from '../context'
import { PreferredTagsConsumer } from '../context/preferred-tags.context'
import * as RootNavigation from '../navigation/AuthNavigation'

export interface IConfirmationModalProps {
  isVisible: boolean
  setVisible: (arg0: boolean) => void
  newContentTags: ITag[]
  fetchedContentTags: ITag[]
}

const getDifference = (current: ITag[], prev: ITag[]) => {
  return current.filter((currentTag) => {
    return !prev.find((prevTag) => prevTag.Value === currentTag.Value)
  })
}

export const checkContentTagsWithLS = (currentContentTags: ITag[]) => {
  const oldContentTags = getContentTagsFromLS()
  const contentTagsDifference = getDifference(
    currentContentTags,
    oldContentTags
  )
  if (
    !contentTagsDifference.length &&
    getDifference(oldContentTags, currentContentTags).length
  ) {
    setContentTagsToLS(currentContentTags)
  }
  return contentTagsDifference
}

export const getContentTagsFromLS = () =>
  JSON.parse(localStorage.getItem(contentTags) || 'null') || []

export const setContentTagsToLS = (currentContentTags: ITag[]) =>
  localStorage.setItem(contentTags, JSON.stringify(currentContentTags))

export const activateNewPreferredTags = (
  tags: ITag[],
  newContentTags: ITag[],
  setPreferredTags: (tags: string[]) => Promise<void>
) => {
  const tagValues = tags.map((tag) => tag.Value)
  const newContentTagValues = newContentTags.map((tag) => tag.Value)
  const preferredTags = concat(tagValues, newContentTagValues)
  setPreferredTags(preferredTags)
}

export default function NewContentTagsModal({
  isVisible,
  setVisible,
  newContentTags,
  fetchedContentTags,
}: IConfirmationModalProps) {
  const title =
    newContentTags.length === 1 ? 'kategória érhető' : 'kategóriák érhetők'
  const activationText =
    newContentTags.length === 1 ? 'aktiválásához' : 'aktiválásukhoz'
  return (
    <View style={styles.centeredView}>
      <Modal
        animationType="none"
        transparent={true}
        visible={isVisible}
        onRequestClose={() => {
          setVisible(false)
        }}
      >
        <UserConsumer>
          {({ setPreferredTags }) => (
            <PreferredTagsConsumer>
              {(tags) => (
                <View style={styles.centeredView}>
                  <View style={styles.modalView}>
                    <Text
                      style={[styles.text, styles.highlight]}
                    >{`Új ${title} el!`}</Text>
                    <View style={styles.listWrapper}>
                      <FlatList
                        data={newContentTags}
                        keyExtractor={(tag) => tag.Value}
                        persistentScrollbar
                        renderItem={({ item }) => (
                          <Text
                            style={styles.listText}
                          >{`\u2022 ${item.Title}`}</Text>
                        )}
                      />
                    </View>
                    <Text style={styles.text}>
                      {`Az ${activationText} kattintson az`}{' '}
                      <Text style={styles.highlight}>Aktiválás</Text>{' '}
                      {`gombra!`}
                    </Text>
                    <View style={styles.buttonView}>
                      <Pressable
                        style={styles.buttonCancel}
                        onPress={() => {
                          setContentTagsToLS(fetchedContentTags)
                          setVisible(false)
                        }}
                      >
                        <Text style={styles.buttonTextCancel}>Mégse</Text>
                      </Pressable>
                      <Pressable
                        style={styles.buttonContinue}
                        disabled={!RootNavigation.navigationRef.isReady()}
                        onPress={() => {
                          activateNewPreferredTags(
                            tags,
                            newContentTags,
                            setPreferredTags
                          )
                          setContentTagsToLS(fetchedContentTags)
                          setVisible(false)
                          RootNavigation.navigate('Categories')
                        }}
                      >
                        <Text style={styles.buttonTextContinue}>Aktiválás</Text>
                      </Pressable>
                    </View>
                  </View>
                </View>
              )}
            </PreferredTagsConsumer>
          )}
        </UserConsumer>
      </Modal>
    </View>
  )
}

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    height: '100%',
    width: '100%',
    backgroundColor: Colors.transparentBackgroundOxfordBlue,
  },
  modalView: {
    padding: 20,
    backgroundColor: 'white',
    borderRadius: 10,
    borderColor: Colors.orangeWeb,
    borderWidth: 3,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    maxWidth: '75vw',
  },
  modalText: {
    marginBottom: 10,
    textAlign: 'center',
  },
  text: {
    alignContent: 'center',
    color: Colors.oxfordBlue,
    fontFamily: 'Roboto_400Regular',
    fontSize: 15,
    marginBottom: 10,
  },
  highlight: {
    fontFamily: 'Roboto_500Medium',
  },
  listText: {
    alignContent: 'center',
    color: Colors.oxfordBlue,
    fontFamily: 'Roboto_400Regular',
    fontSize: 14,
    marginBottom: 5,
  },
  listWrapper: {
    marginBottom: 10,
    marginLeft: 10,
    maxHeight: 75,
  },
  buttonView: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  buttonTextCancel: {
    color: Colors.oxfordBlue,
    fontFamily: 'Roboto_400Regular',
    fontSize: 13,
  },
  buttonTextContinue: {
    color: Colors.white,
    fontFamily: 'Roboto_400Regular',
    fontSize: 13,
  },
  buttonCancel: {
    marginLeft: 5,
    padding: 10,
    width: 'fit-content',
    alignItems: 'center',
    outlineStyle: 'none',
  },
  buttonContinue: {
    marginLeft: 10,
    padding: 5,
    paddingHorizontal: 15,
    width: 'fit-content',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.oxfordBlue,
    borderRadius: 5,
    outlineStyle: 'none',
  },
})
