import React from 'react'
import scopeLogo from '@variants/scope/logo.png'
import mirrorLogo from '@variants/mirror/logo.png'
import scopeIcon from '@variants/scope/icon.png'
import mirrorIcon from '@variants/mirror/icon.png'
import observerLogo from '@variants/observer/logo.png'
import observerIcon from '@variants/observer/icon.png'
import { View, Text } from 'react-native'
import { Image } from 'react-native-elements'
import Browser from '../constants/Browser'

const getBundleContent = () =>
  ({
    scope: {
      logo: scopeLogo,
      icon: scopeIcon,
      imageWidth: '35vw',
      registrationHeader:
        'Fedezd fel az általad preferált kategóriájú híreket több száz nemzetközi forrásból! Kérj értesítést a hírekről, mentsd el a legjobb cikkeket vagy oszd meg őket. Egyéni beállításaiddal személyre szabott átfogó képet kapsz a világ napi híreiről!',
      registrationQuestion: 'Kérdés esetén írj nekünk az ',
      registrationEmail: 'info@mirrortheweb.com',
      registrationAddress: 'címre!',
      teamPrefix: 'a',
      successCardHeader: 'Sikeres Telepítés!',
      successCardSubHeader:
        'Sikeresen telepítetted az alkalmazást, kérjük nyisd meg azt, majdaktiváld felhasználódat!',
      successCardText:
        'Amennyiben további kérdésed maradt lépj kapcsolatba ügyfélszolgálatunkkal!',
      installHeader:
        'A telepítési képernyő pillanatokon belül meg kell, hogy jelenjen.',
      installText1:
        'Amennyiben mégsem jelenne meg, valószínűleg az alkalmazás már telepítve van eszközödön.',
      installText2:
        'Amennyiben további kérdésed maradt lépj kapcsolatba ügyfélszolgálatunkkal!',
      guideHeader:
        'Úgy tűnik iOS környezetből nyitottad meg rendszerünket, így az alkalmazás csak manuálisan telepíthető.',
      guideSteps: (styles: any) => {
        return (
          <View style={styles.list}>
            <Text style={styles.listItem}>
              {`\u2022 Kattints a "Megosztás"  `}
              <Image
                placeholderStyle={styles.imagePlaceholder}
                containerStyle={styles.imageContainer}
                style={styles.image}
                resizeMode={'stretch'}
                source={{
                  uri: `data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMiIgZGF0YS1uYW1lPSJMYXllciAyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMC44OCAyNy4yNSI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOm5vbmU7c3Ryb2tlOiMyMzFmMjA7fTwvc3R5bGU+PC9kZWZzPjx0aXRsZT5TYWZhcmlfU2hhcmU8L3RpdGxlPjxwb2x5bGluZSBjbGFzcz0iY2xzLTEiIHBvaW50cz0iMTMuMTMgOCAyMC4zOCA4IDIwLjM4IDI2Ljc1IDAuNSAyNi43NSAwLjUgOCA3LjUgOCIvPjxsaW5lIGNsYXNzPSJjbHMtMSIgeDE9IjEwLjQ0IiB5MT0iMTciIHgyPSIxMC40NCIvPjxsaW5lIGNsYXNzPSJjbHMtMSIgeDE9IjEwLjQ4IiB5MT0iMC4zOCIgeDI9IjE1LjI4IiB5Mj0iNS4xOCIvPjxsaW5lIGNsYXNzPSJjbHMtMSIgeDE9IjEwLjQ0IiB5MT0iMC4zOCIgeDI9IjUuNjQiIHkyPSI1LjE4Ii8+PC9zdmc+`,
                }}
              />
              {` gombra Safari böngésződ ${Browser.whereIsShare}`}
            </Text>
            <Text style={styles.listItem}>
              {`\u2022 Görgess (amennyiben szükséges) amíg meg nem találod a "Főképernyőhöz adás" `}
              <Image
                placeholderStyle={styles.imagePlaceholder}
                containerStyle={styles.imageContainer}
                style={styles.image}
                resizeMode={'stretch'}
                source={{
                  uri: `data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMiIgZGF0YS1uYW1lPSJMYXllciAyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzNSAzNSI+PHRpdGxlPlNhZmFyaV9BMkhTPC90aXRsZT48cmVjdCB3aWR0aD0iMzUiIGhlaWdodD0iMzUiIHJ4PSI4IiByeT0iOCIgc3R5bGU9ImZpbGw6IzhmOGY4ZiIvPjxsaW5lIHgxPSIyNC43NSIgeTE9IjE3LjUiIHgyPSIxMC4yNSIgeTI9IjE3LjUiIHN0eWxlPSJmaWxsOm5vbmU7c3Ryb2tlOiNmZmY7c3Ryb2tlLXdpZHRoOjJweCIvPjxsaW5lIHgxPSIxNy41IiB5MT0iMTAuMjUiIHgyPSIxNy41IiB5Mj0iMjQuNzUiIHN0eWxlPSJmaWxsOm5vbmU7c3Ryb2tlOiNmZmY7c3Ryb2tlLXdpZHRoOjJweCIvPjwvc3ZnPg==`,
                }}
              />
              {` gombot`}
            </Text>
          </View>
        )
      },
      guideFooter:
        'Amennyiben további kérdésed maradt lépj kapcsolatba ügyfélszolgálatunkkal!',
      failedInstallHeader:
        'Úgy tűnik akadályba ütköztél az alkalmazás telepítése során. Ennek több oka is lehet:',
      failedInstallStep1: `\u2022 Az alkalmazás már telepítve van az eszközödön`,
      failedInstallStep2: `\u2022 Az alkalmazás nem megfelelő környezetből lett megnyitva`,
      failedInstallStep3: `\u2022 A böngésződ nem támogatja az alkalmazás letöltését`,
      failedInstallHint:
        'Kérjük próbáld újra a "Chrome" vagy "Edge" böngésző használatával!',
      failedInfo:
        'Amennyiben további kérdésed maradt lépj kapcsolatba ügyfélszolgálatunkkal!',
    },
    mirror: {
      logo: mirrorLogo,
      icon: mirrorIcon,
      imageWidth: '35vw',
      registrationHeader:
        'Fedezd fel az általad preferált kategóriájú híreket több száz nemzetközi forrásból! Kérj értesítést a hírekről, mentsd el a legjobb cikkeket vagy oszd meg őket. Egyéni beállításaiddal személyre szabott átfogó képet kapsz a világ napi híreiről!',
      registrationQuestion: 'Kérdés esetén írj nekünk az ',
      registrationEmail: 'info@mirrortheweb.com',
      registrationAddress: 'címre!',
      teamPrefix: 'a',
      successCardHeader: 'Sikeres Telepítés!',
      successCardSubHeader:
        'Sikeresen telepítetted az alkalmazást, kérjük nyisd meg azt, majdaktiváld felhasználódat!',
      successCardText:
        'Amennyiben további kérdésed maradt lépj kapcsolatbaügyfélszolgálatunkkal!',
      installHeader:
        'A telepítési képernyő pillanatokon belül meg kell, hogy jelenjen.',
      installText1:
        'Amennyiben mégsem jelenne meg, valószínűleg az alkalmazás már telepítve van eszközödön.',
      installText2:
        'Amennyiben további kérdésed maradt lépj kapcsolatba ügyfélszolgálatunkkal!',
      guideHeader:
        'Úgy tűnik iOS környezetből nyitottad meg rendszerünket, így az alkalmazás csak manuálisan telepíthető.',
      guideSteps: (styles: any) => {
        return (
          <View style={styles.list}>
            <Text style={styles.listItem}>
              {`\u2022 Kattints a "Megosztás"  `}
              <Image
                placeholderStyle={styles.imagePlaceholder}
                containerStyle={styles.imageContainer}
                style={styles.image}
                resizeMode={'stretch'}
                source={{
                  uri: `data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMiIgZGF0YS1uYW1lPSJMYXllciAyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMC44OCAyNy4yNSI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOm5vbmU7c3Ryb2tlOiMyMzFmMjA7fTwvc3R5bGU+PC9kZWZzPjx0aXRsZT5TYWZhcmlfU2hhcmU8L3RpdGxlPjxwb2x5bGluZSBjbGFzcz0iY2xzLTEiIHBvaW50cz0iMTMuMTMgOCAyMC4zOCA4IDIwLjM4IDI2Ljc1IDAuNSAyNi43NSAwLjUgOCA3LjUgOCIvPjxsaW5lIGNsYXNzPSJjbHMtMSIgeDE9IjEwLjQ0IiB5MT0iMTciIHgyPSIxMC40NCIvPjxsaW5lIGNsYXNzPSJjbHMtMSIgeDE9IjEwLjQ4IiB5MT0iMC4zOCIgeDI9IjE1LjI4IiB5Mj0iNS4xOCIvPjxsaW5lIGNsYXNzPSJjbHMtMSIgeDE9IjEwLjQ0IiB5MT0iMC4zOCIgeDI9IjUuNjQiIHkyPSI1LjE4Ii8+PC9zdmc+`,
                }}
              />
              {` gombra Safari böngésződ ${Browser.whereIsShare}`}
            </Text>
            <Text style={styles.listItem}>
              {`\u2022 Görgess (amennyiben szükséges) amíg meg nem találod a "Főképernyőhöz adás" `}
              <Image
                placeholderStyle={styles.imagePlaceholder}
                containerStyle={styles.imageContainer}
                style={styles.image}
                resizeMode={'stretch'}
                source={{
                  uri: `data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMiIgZGF0YS1uYW1lPSJMYXllciAyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzNSAzNSI+PHRpdGxlPlNhZmFyaV9BMkhTPC90aXRsZT48cmVjdCB3aWR0aD0iMzUiIGhlaWdodD0iMzUiIHJ4PSI4IiByeT0iOCIgc3R5bGU9ImZpbGw6IzhmOGY4ZiIvPjxsaW5lIHgxPSIyNC43NSIgeTE9IjE3LjUiIHgyPSIxMC4yNSIgeTI9IjE3LjUiIHN0eWxlPSJmaWxsOm5vbmU7c3Ryb2tlOiNmZmY7c3Ryb2tlLXdpZHRoOjJweCIvPjxsaW5lIHgxPSIxNy41IiB5MT0iMTAuMjUiIHgyPSIxNy41IiB5Mj0iMjQuNzUiIHN0eWxlPSJmaWxsOm5vbmU7c3Ryb2tlOiNmZmY7c3Ryb2tlLXdpZHRoOjJweCIvPjwvc3ZnPg==`,
                }}
              />
              {` gombot`}
            </Text>
          </View>
        )
      },
      guideFooter:
        'Amennyiben további kérdésed maradt lépj kapcsolatba ügyfélszolgálatunkkal!',
      failedInstallHeader:
        'Úgy tűnik akadályba ütköztél az alkalmazás telepítése során. Ennek több oka is lehet:',
      failedInstallStep1: `\u2022 Az alkalmazás már telepítve van az eszközödön`,
      failedInstallStep2: `\u2022 Az alkalmazás nem megfelelő környezetből lett megnyitva`,
      failedInstallStep3: `\u2022 A böngésződ nem támogatja az alkalmazás letöltését`,
      failedInstallHint:
        'Kérjük próbáld újra a "Chrome" vagy "Edge" böngésző használatával!',
      failedInfo:
        'Amennyiben további kérdésed maradt lépj kapcsolatba ügyfélszolgálatunkkal!',
    },
    observer: {
      logo: observerLogo,
      icon: observerIcon,
      imageWidth: '75vw',
      registrationHeader: '',
      registrationQuestion: 'További kérdések esetén írjon nekünk az ',
      registrationEmail: 'info@observer.hu',
      registrationAddress: 'e-mail címre.',
      teamPrefix: 'az',
      successCardHeader: 'Sikeres telepítés!',
      successCardSubHeader: 'Az alkalmazás telepítése sikeresen befejeződött!',
      successCardText:
        'Kérjük, nyissa meg az alkalmazást a fő oldalon található ikon segítségével! ',
      installHeader: 'A telepítési képernyő hamarosan meg kell, hogy jelenjen.',
      installText1:
        'Amennyiben a telepítési folyamat nem indulna el, elképzelhető, hogy az alkalmazás már telepítve van az eszközén vagy nem a megfelelő böngészőt használja.',
      installText2: (
        <Text>
          Kérjük, ellenőrizze, hogy települt-e a főképernyőre az alkalmazás
          ikonja, ha nem, akkor próbálja újra a telepítést
          {Browser.isIOS ? (
            <Text style={{ fontWeight: 'bold' }}> Safari </Text>
          ) : (
            <Text style={{ fontWeight: 'bold' }}> Chrome </Text>
          )}
          böngésző használatával!
        </Text>
      ),
      installText3:
        'Ha bármilyen probléma adódna, kérjük, írjon nekünk az info@observer.hu e-mail címre!',
      installText4: 'Üdvözlettel,',
      installText5: 'az Observer csapata',
      guideHeader:
        'iOS-eszköz használatánál az alkalmazás manuálisan telepíthető az alábbi lépések szerint:',
      guideSteps: (styles: any) => {
        return (
          <View style={styles.list}>
            <Text style={styles.listItem}>
              {`1. Kattintson a `}
              <Text style={{ fontWeight: 'bold' }}>„Megosztás” </Text>
              <Image
                placeholderStyle={styles.imagePlaceholder}
                containerStyle={styles.imageContainer}
                style={styles.image}
                resizeMode={'stretch'}
                source={{
                  uri: `data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMiIgZGF0YS1uYW1lPSJMYXllciAyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMC44OCAyNy4yNSI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOm5vbmU7c3Ryb2tlOiMyMzFmMjA7fTwvc3R5bGU+PC9kZWZzPjx0aXRsZT5TYWZhcmlfU2hhcmU8L3RpdGxlPjxwb2x5bGluZSBjbGFzcz0iY2xzLTEiIHBvaW50cz0iMTMuMTMgOCAyMC4zOCA4IDIwLjM4IDI2Ljc1IDAuNSAyNi43NSAwLjUgOCA3LjUgOCIvPjxsaW5lIGNsYXNzPSJjbHMtMSIgeDE9IjEwLjQ0IiB5MT0iMTciIHgyPSIxMC40NCIvPjxsaW5lIGNsYXNzPSJjbHMtMSIgeDE9IjEwLjQ4IiB5MT0iMC4zOCIgeDI9IjE1LjI4IiB5Mj0iNS4xOCIvPjxsaW5lIGNsYXNzPSJjbHMtMSIgeDE9IjEwLjQ0IiB5MT0iMC4zOCIgeDI9IjUuNjQiIHkyPSI1LjE4Ii8+PC9zdmc+`,
                }}
              />
              {` gombra Safari böngésző ${Browser.whereIsShareObserver} sávjában.`}
            </Text>
            <Text style={styles.listItem}>
              {`2. Válassza ki a `}
              <Text style={{ fontWeight: 'bold' }}>„Főképernyőhöz adás” </Text>
              <Image
                placeholderStyle={styles.imagePlaceholder}
                containerStyle={styles.imageContainer}
                style={styles.image}
                resizeMode={'stretch'}
                source={{
                  uri: `data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMiIgZGF0YS1uYW1lPSJMYXllciAyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzNSAzNSI+PHRpdGxlPlNhZmFyaV9BMkhTPC90aXRsZT48cmVjdCB3aWR0aD0iMzUiIGhlaWdodD0iMzUiIHJ4PSI4IiByeT0iOCIgc3R5bGU9ImZpbGw6IzhmOGY4ZiIvPjxsaW5lIHgxPSIyNC43NSIgeTE9IjE3LjUiIHgyPSIxMC4yNSIgeTI9IjE3LjUiIHN0eWxlPSJmaWxsOm5vbmU7c3Ryb2tlOiNmZmY7c3Ryb2tlLXdpZHRoOjJweCIvPjxsaW5lIHgxPSIxNy41IiB5MT0iMTAuMjUiIHgyPSIxNy41IiB5Mj0iMjQuNzUiIHN0eWxlPSJmaWxsOm5vbmU7c3Ryb2tlOiNmZmY7c3Ryb2tlLXdpZHRoOjJweCIvPjwvc3ZnPg==`,
                }}
              />
              {` lehetőséget, ha szükséges, görgessen.`}
            </Text>
          </View>
        )
      },
      guideFooter: '',
      failedInstallHeader:
        'Akadályba ütközött az alkalmazás telepítése során, ennek több oka is lehet: ',
      failedInstallStep1: `\u2022 Az alkalmazás már telepítve van az eszközödön`,
      failedInstallStep2: `\u2022 Az alkalmazás nem ${
        Browser.isIOS ? 'Safari' : 'Chrome'
      } böngészőből lett megnyitva.`,
      failedInstallHint: (
        <Text>
          Kérjük próbáld újra a
          {Browser.isIOS ? (
            <Text style={{ fontWeight: 'bold' }}> Safari </Text>
          ) : (
            <Text style={{ fontWeight: 'bold' }}> Chrome </Text>
          )}
          böngésző használatával!
        </Text>
      ),
      failedInfo:
        'Ha bármilyen probléma adódna, kérjük, írjon nekünk az info@observer.hu e-mail címre!',
      failedInfo2: 'Üdvözlettel,',
      failedInfo3: 'az Observer csapata',
    },
  }[window.bundle ?? 'scope'])

export default getBundleContent
